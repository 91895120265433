var render = function () {
  var _vm$error, _vm$error2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "/",
      "noback": "",
      "isBackToEventWithActiveRoom": ""
    }
  }), _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "form-order"
  }, [_c('div', {
    staticClass: "title-page mb-3"
  }, [_vm._v("Detail Pesanan")]), _c('div', {
    staticClass: "card-shadow mb-3"
  }, [_c('div', {
    staticClass: "content-space-between event-calendar"
  }, [_vm._m(0), _c('span', [_vm._v(_vm._s(_vm.formatDateTimesDay(_vm.eventDate)))])]), _c('div', {
    staticClass: "title-event content-space-between"
  }, [_c('img', {
    staticClass: "banner-small me-3",
    attrs: {
      "src": _vm.imgBanner
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "w-100"
  }, [_vm._v(_vm._s(_vm.eventName))])]), _c('div', {
    staticClass: "container-ticket-card"
  }, [_c('img', {
    staticClass: "me-2",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket-grey.png")
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v("Tiket Kamu")]), _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.ticketData, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "total-ticket"
    }, [(item === null || item === void 0 ? void 0 : item.qty) > 0 ? _c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.category))]) : _vm._e(), (item === null || item === void 0 ? void 0 : item.qty) > 0 ? _c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.qty) + " Tiket")]) : _vm._e()]);
  }), 0)])]), _vm._m(1), _c('div', {
    staticClass: "data-ordered mb-3"
  }, [_c('div', {
    staticClass: "content-space-between"
  }, [_c('div', {
    staticClass: "sub-title-container mb-1"
  }, [_vm._v(" Detail Pemesanan ")]), _c('p', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "badges-timer m-0 "
  }, [_vm._v(_vm._s(_vm.hoursTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))]), _c('span', {
    staticClass: "font-size-12 font-weight-600 text-primary"
  }, [_vm._v(" : ")]), _c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.minutesTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))]), _vm._v(" "), _c('span', {
    staticClass: "font-size-12 font-weight-600 text-primary"
  }, [_vm._v(":")]), _vm._v(" "), _c('span', {
    staticClass: "badges-timer m-0"
  }, [_vm._v(_vm._s(_vm.secondsTimer.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })))])])]), _c('div', {
    class: ['desc-card', {
      'border-danger': _vm.notFilledOrder
    }]
  }, [_c('div', {
    staticClass: "content-space-between"
  }, [_c('span', {
    staticClass: "font-weight-600 font-size-12"
  }, [_vm._v(_vm._s(_vm.formOrderer.name ? _vm.formOrderer.name : 'Nama Pemesan belum diisi'))]), _c('button', {
    staticClass: "font-size-12 button-default text-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/form-data');
      }
    }
  }, [_c('img', {
    staticClass: "icon-12",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-pencil.png")
    }
  })])]), _c('p', {
    staticClass: "font-size-10 font-weight-400 m-0"
  }, [_vm._v(_vm._s(_vm.formOrderer.email ? _vm.formOrderer.email : 'Alamat Email belum diisi'))]), _c('p', {
    staticClass: "font-size-10 font-weight-400 m-0"
  }, [_vm._v(_vm._s(_vm.formOrderer.phonenumber ? _vm.formOrderer.phonenumber : 'Nomor Handphone belum diisi'))])]), _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [_vm.notFilledOrder ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Data Pemesan tidak boleh kosong")]) : _vm._e()])]), _c('div', {
    staticClass: "data-visitor"
  }, [_c('div', {
    staticClass: "sub-title-container mb-2"
  }, [_vm._v(" Detail Pengunjung ")]), _vm._l(_vm.formVisitor, function (item, key) {
    return _c('div', {
      key: key,
      on: {
        "click": function ($event) {
          return _vm.editVisitor(key);
        }
      }
    }, [_c('button', {
      class: ['cat-visit button-default w-100 mt-2', {
        'border-danger': _vm.notDoneVisitor && (item === null || item === void 0 ? void 0 : item.isDone) == false
      }],
      attrs: {
        "type": "button"
      }
    }, [_c('div', {
      staticClass: "ticket-detail justify-content-start"
    }, [_c('p', {
      staticClass: "font-weight-400 font-size-10 ticket-detail-name m-0"
    }, [_vm._v("Tiket Category : " + _vm._s(item === null || item === void 0 ? void 0 : item.category))]), _c('p', {
      staticClass: "font-weight-600 font-size-10 text-black m-0"
    }, [_vm._v(_vm._s(item !== null && item !== void 0 && item.name ? item === null || item === void 0 ? void 0 : item.name : 'Isi Data Pengunjung') + " ")])]), _c('div', {
      staticClass: "font-size-12 cursor-pointer align-self-center"
    }, [(item === null || item === void 0 ? void 0 : item.isDone) == false ? _c('img', {
      staticClass: "icon-12",
      attrs: {
        "src": require("../assets/img/icon/fi-rr-angle-small-right.png")
      }
    }) : _c('img', {
      staticClass: "icon-12",
      attrs: {
        "src": require("../assets/img/icon/check-success.png")
      }
    })])]), _c('div', {
      staticClass: "text-danger font-size-10 mt-1"
    }, [_vm.notDoneVisitor && (item === null || item === void 0 ? void 0 : item.isDone) == false ? _c('p', {
      staticClass: "m-0"
    }, [_vm._v("Data Tiket Pengunjung tidak boleh kosong")]) : _vm._e()])]);
  })], 2)])]), _c('div', {
    class: ['footer-form', {
      'rounded-footer': _vm.showDetail
    }]
  }, [_vm.showDetail == true ? _c('div', {
    staticClass: "container-summary"
  }, [_c('div', {
    staticClass: "summary-detail mb-2"
  }, [_c('div', {
    staticClass: "summary-title font-weight-600"
  }, [_vm._v("Rincian Pembayaran")]), _c('b-icon-x-lg', {
    staticClass: "summary-detail-close cursor-pointer",
    on: {
      "click": function ($event) {
        _vm.showDetail = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "card-summary"
  }, [_c('div', {
    staticClass: "container-ticket mb-2"
  }, [_c('img', {
    staticClass: "icon-12 me-1",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket-grey.png")
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font-weight-600"
  }, [_vm._v("Tiket Kamu")]), _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.ticketData, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "total-ticket"
    }, [item.qty > 0 ? _c('span', [_vm._v(_vm._s(item.qty) + "x " + _vm._s(item.category))]) : _vm._e(), item.qty > 0 ? _c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.priceQty)))]) : _vm._e()]);
  }), 0), _vm._m(2)])])]) : _vm._e(), _c('div', {
    staticClass: "summary-qty mb-3 mt-2"
  }, [_c('span', [_vm._v("Total Pembayaran")]), _c('span', {
    class: ['font-weight-600', {
      'cursor-pointer': _vm.showDetail == false
    }],
    on: {
      "click": function ($event) {
        _vm.showDetail = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.ticketSummary.priceTotal)) + " "), _vm.showDetail == false ? _c('img', {
    staticClass: "icon-arrow-price",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-down.png")
    }
  }) : _c('img', {
    staticClass: "icon-arrow-price",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-angle-small-up.png")
    }
  })])]), _c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.checkData(true);
      }
    }
  }, [_vm._v(" Pembayaran ")])]), _c('b-modal', {
    ref: "failed-modal",
    attrs: {
      "size": "sm",
      "id": "failed-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "warning-icon mb-2",
    attrs: {
      "src": require("../assets/img/warning-icon.png")
    }
  }), _c('p', {
    staticClass: "font-size-20 font-weight-600 mb-2"
  }, [_vm._v(_vm._s((_vm$error = _vm.error) === null || _vm$error === void 0 ? void 0 : _vm$error.title))]), _c('p', {
    staticClass: "font-size-12 font-weight-400 mb-5"
  }, [_vm._v(_vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.message))]), _c('button', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('failed-modal');
      }
    }
  }, [_vm._v("Ok, Terima Kasih")])])]), _c('b-modal', {
    ref: "confirm-modal",
    attrs: {
      "size": "sm",
      "id": "confirm-modal",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-600 mb-2"
  }, [_vm._v("Apakah Anda yakin ingin melanjutkan Pembayaran?")]), _c('p', {
    staticClass: "font-size-10 font-weight-400 mb-4"
  }, [_vm._v("Pastikan Data Pemesan dan Data Pengunjung sudah sesuai. Ketika melanjutkan pembayaran tidak bisa mengubah Data Pemesan dan Pengunjung.")]), _c('div', {
    staticClass: "w-100 me-0 d-flex flex-row justify-content-end align-items-center"
  }, [_c('button', {
    staticClass: "btn button-default text-primary me-3 font-size-10 font-weight-600",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('confirm-modal');
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "btn btn-primary font-size-10 font-weight-600 btn-xxsm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.orderTicket();
      }
    }
  }, [_vm._v("Pembayaran")])])])]), _c('b-modal', {
    ref: "confirm-modal-back",
    attrs: {
      "size": "sm",
      "id": "confirm-modal-back",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "font-size-12 font-weight-600 mb-2"
  }, [_vm._v("Apakah Anda yakin ingin meninggalkan halaman ini?")]), _c('p', {
    staticClass: "font-size-10 font-weight-400 mb-4"
  }, [_vm._v("Anda harus membatalkan pesanan terlebih dahulu untuk meninggalkan halaman ini")]), _c('div', {
    staticClass: "w-100 me-0 d-flex flex-row justify-content-end align-items-center"
  }, [_c('button', {
    staticClass: "btn button-default text-primary me-3 font-size-10 font-weight-600",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('confirm-modal-back');
      }
    }
  }, [_vm._v("Tetap Disini")]), _c('button', {
    staticClass: "btn button-rounded bg-danger font-size-10 font-weight-600 btn-xxsm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.BackToEventWithActiveRoom();
      }
    }
  }, [_vm._v("Cancel Order")])])])]), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    staticClass: "me-2",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-calendar-grey.png")
    }
  }), _c('span', [_vm._v("Tanggal Event")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toast-fixed info mb-3"
  }, [_c('div', {
    staticClass: "message-claimed"
  }, [_c('img', {
    staticClass: "me-1",
    attrs: {
      "src": require("../assets/img/fi-rr-info.png")
    }
  }), _c('div', {
    staticClass: "w-100"
  }, [_c('p', {
    staticClass: "m-0 font-size-12 font-weight-600 text-black"
  }, [_vm._v("Lengkapi Detail Pesanan!")]), _c('p', {
    staticClass: "m-0 font-size-10 font-weight-400 text-black"
  }, [_vm._v("Isi terlebih dahulu Detail Pemesan dan Detail Pengunjung untuk melanjutkan pembayaran. Data akan digunakan untuk mengirimkan Invoice dan Tiket Anda. ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toast-fixed info mt-3"
  }, [_c('div', {
    staticClass: "message-claimed"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/img/fi-rr-info.png")
    }
  }), _c('span', {
    staticClass: "ms-2"
  }, [_vm._v("Total Pembayaran tiket belum termasuk Biaya Layanan pada masing-masing metode pembayaran yang digunakan")])])]);
}]

export { render, staticRenderFns }