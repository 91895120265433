var render = function () {
  var _vm$formVisitor, _vm$formVisitor2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "title": "",
      "url": "/detail-order"
    }
  }), _c('div', {
    staticClass: "card-page"
  }, [_c('div', {
    staticClass: "form-order"
  }, [_c('div', {
    staticClass: "title-page mb-3"
  }, [_vm._v("Data Pengunjung")]), _vm._m(0), _c('div', {
    staticClass: "content-space-between"
  }, [_c('p', {
    staticClass: "font-size-14 text-black font-weight-400"
  }, [_vm._v("Tiket Kategori")]), _c('div', {
    staticClass: "badge-ticket"
  }, [_vm._v(_vm._s((_vm$formVisitor = _vm.formVisitor) === null || _vm$formVisitor === void 0 ? void 0 : _vm$formVisitor.category))])]), ((_vm$formVisitor2 = _vm.formVisitor) === null || _vm$formVisitor2 === void 0 ? void 0 : _vm$formVisitor2.id) == 1 ? _c('div', {
    staticClass: "content-space-between mb-2"
  }, [_c('p', {
    staticClass: "font-size-14 text-black font-weight-400"
  }, [_vm._v("Sama dengan Data Pemesan")]), _c('div', {
    staticClass: "form-check form-switch form-switch-md"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sameOrderer,
      expression: "sameOrderer"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "role": "switch",
      "id": "flexSwitchCheckDefault",
      "disabled": !_vm.dataOrder
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.sameOrderer) ? _vm._i(_vm.sameOrderer, true) > -1 : _vm.sameOrderer
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.sameOrderer,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = true,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.sameOrderer = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.sameOrderer = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.sameOrderer = $$c;
        }
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formVisitor.name.$model,
      expression: "$v.formVisitor.name.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formVisitor.name.$error
    }],
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "Masukkan Nama Lengkap Anda"
    },
    domProps: {
      "value": _vm.$v.formVisitor.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formVisitor.name, "$model", $event.target.value);
      }
    }
  }), _vm._m(1)]), _vm.$v.formVisitor.name.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formVisitor.name.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nama lengkap tidak boleh kosong")]) : _vm._e(), !_vm.$v.formVisitor.name.minLength || !_vm.$v.formVisitor.name.maxLength ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nama lengkap terdiri dari 3 - 40 karakter")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formVisitor.phonenumber.$model,
      expression: "$v.formVisitor.phonenumber.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formVisitor.phonenumber.$error
    }],
    attrs: {
      "type": "text",
      "id": "phone",
      "placeholder": "Masukkan Nomor Handphone Anda"
    },
    domProps: {
      "value": _vm.$v.formVisitor.phonenumber.$model
    },
    on: {
      "focus": function ($event) {
        _vm.duplicatePhone = false;
      },
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formVisitor.phonenumber, "$model", $event.target.value);
      }, function ($event) {
        _vm.formVisitor.phonenumber = _vm.formVisitor.phonenumber.replace(/[^0-9]/g, '');
      }],
      "blur": _vm.checkPhone
    }
  }), _vm._m(2)]), _vm.$v.formVisitor.phonenumber.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formVisitor.phonenumber.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nomor handphone tidak boleh kosong")]) : _vm._e(), !_vm.$v.formVisitor.phonenumber.minLength || !_vm.$v.formVisitor.phonenumber.maxLength ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nomor Handphone terdiri dari 10 -15 digit")]) : _vm._e()]) : _vm._e(), _vm.duplicatePhone == true ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v("Nomor Handphone sudah digunakan")])]) : _vm._e()]), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "form-floating"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$v.formVisitor.email.$model,
      expression: "$v.formVisitor.email.$model"
    }],
    class: ['form-control', {
      'border-danger': _vm.$v.formVisitor.email.$error
    }],
    attrs: {
      "type": "email",
      "id": "email",
      "placeholder": "Masukkan Alamat Email Anda"
    },
    domProps: {
      "value": _vm.$v.formVisitor.email.$model
    },
    on: {
      "focus": function ($event) {
        _vm.duplicateEmail = false;
      },
      "blur": _vm.checkEmail,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.formVisitor.email, "$model", $event.target.value);
      }
    }
  }), _vm._m(3)]), _vm.$v.formVisitor.email.$error ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [!_vm.$v.formVisitor.email.required ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Alamat email tidak boleh kosong")]) : _vm._e(), !_vm.$v.formVisitor.email.email ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Format alamat email tidak valid")]) : _vm._e()]) : _vm._e(), _vm.duplicateEmail == true ? _c('div', {
    staticClass: "text-danger font-size-10 mt-1"
  }, [_c('p', {
    staticClass: "m-0"
  }, [_vm._v("Alamat Email sudah digunakan")])]) : _vm._e()])])]), _c('div', {
    class: ['footer-form', {
      'rounded-footer': _vm.showDetail
    }]
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button",
      "disabled": _vm.$v.formVisitor.$invalid
    },
    on: {
      "click": function ($event) {
        return _vm.saveVisitor();
      }
    }
  }, [_vm._v(" Simpan Data ")])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toast-fixed info mb-3"
  }, [_c('div', {
    staticClass: "message-claimed"
  }, [_c('img', {
    staticClass: "me-1",
    attrs: {
      "src": require("../assets/img/fi-rr-info.png")
    }
  }), _c('div', {
    staticClass: "w-100"
  }, [_c('p', {
    staticClass: "m-0 font-size-12 font-weight-600 text-black"
  }, [_vm._v("Pastikan data yang diisi benar!")]), _c('p', {
    staticClass: "m-0 font-size-10 font-weight-400 text-black"
  }, [_vm._v("E-Ticket akan diterbitkan dan dikirimkan melalui Alamat Email yang Anda isi pada form dibawah")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Nama Lengkap "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "phone"
    }
  }, [_vm._v("Nomor Handphone "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Alamat Email "), _c('span', {
    staticClass: "text-danger required-label"
  }, [_vm._v("*")])]);
}]

export { render, staticRenderFns }