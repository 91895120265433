<template>
  <div class="container-page">
    <HeaderComp title="" url="/" noback isBackToEventWithActiveRoom />
    <div class="card-page">
      <div class="form-order">
        <div class="title-page mb-3">Detail Pesanan</div>
        <div class="card-shadow mb-3">
          <div class="content-space-between event-calendar">
            <div><img src="../assets/img/icon/fi-rr-calendar-grey.png" class="me-2" /><span>Tanggal Event</span></div>
            <span>{{
              formatDateTimesDay(eventDate) }}</span>
          </div>
          <div class="title-event content-space-between">
            <img :src="imgBanner" class="banner-small me-3"> <span class="w-100">{{ eventName }}</span>
          </div>
          <div class="container-ticket-card">
            <img src="../assets/img/icon/fi-rr-ticket-grey.png" class="me-2" /> <span class="font-weight-600">Tiket
              Kamu</span>
            <div class="mt-2">
              <div class="total-ticket" v-for="(item, key) in ticketData" :key="key">
                <span v-if="item?.qty > 0">{{ item?.category }}</span>
                <span v-if="item?.qty > 0">{{ item?.qty }} Tiket</span>
              </div>
            </div>
          </div>
        </div>
        <div class="toast-fixed info mb-3">
          <div class="message-claimed">
            <img src="../assets/img/fi-rr-info.png" class="me-1" />
            <div class="w-100">
              <p class="m-0 font-size-12 font-weight-600 text-black">Lengkapi Detail Pesanan!</p>
              <p class="m-0 font-size-10 font-weight-400 text-black">Isi terlebih dahulu Detail Pemesan dan Detail
                Pengunjung untuk melanjutkan pembayaran. Data akan digunakan untuk mengirimkan Invoice dan Tiket Anda.
              </p>
            </div>
          </div>
        </div>
        <div class="data-ordered mb-3">
          <div class="content-space-between">
            <div class="sub-title-container mb-1">
            Detail Pemesanan
          </div>
            <p class="mb-2"><span class="badges-timer m-0 ">{{
              hoursTimer.toLocaleString('en-US',
                { minimumIntegerDigits: 2, useGrouping: false }) }}</span><span
                class="font-size-12 font-weight-600 text-primary"> : </span><span class="badges-timer m-0">{{
              minutesTimer.toLocaleString('en-US',
                { minimumIntegerDigits: 2, useGrouping: false }) }}</span> <span
                class="font-size-12 font-weight-600 text-primary">:</span> <span class="badges-timer m-0">{{
              secondsTimer.toLocaleString('en-US',
                { minimumIntegerDigits: 2, useGrouping: false }) }}</span></p>
          </div>

          <div :class="['desc-card', { 'border-danger': notFilledOrder }]">
            <div class="content-space-between">
              <span class="font-weight-600 font-size-12">{{ formOrderer.name ? formOrderer.name :
              'Nama Pemesan belum diisi' }}</span>
              <button type="button" class="font-size-12 button-default text-primary"
                @click="$router.push('/form-data')"><img src="../assets/img/icon/fi-rr-pencil.png"
                  class="icon-12" /></button>
            </div>
            <p class="font-size-10 font-weight-400 m-0">{{ formOrderer.email ? formOrderer.email :
              'Alamat Email belum diisi' }}</p>
            <p class="font-size-10 font-weight-400 m-0">{{ formOrderer.phonenumber ? formOrderer.phonenumber :
              'Nomor Handphone belum diisi' }}</p>
          </div>
          <div class="text-danger font-size-10 mt-1">
            <p class="m-0" v-if="notFilledOrder">Data Pemesan tidak boleh kosong</p>
          </div>
        </div>
        <div class="data-visitor">
          <div class="sub-title-container mb-2">
            Detail Pengunjung
          </div>
          <div v-for="(item, key) in formVisitor" :key="key" @click="editVisitor(key)">
            <button type="button"
              :class="['cat-visit button-default w-100 mt-2', { 'border-danger': notDoneVisitor && item?.isDone == false }]">
              <div class="ticket-detail justify-content-start">
                <p class="font-weight-400 font-size-10 ticket-detail-name m-0">Tiket Category : {{ item?.category }}</p>
                <p class="font-weight-600 font-size-10 text-black m-0">{{ item?.name ? item?.name : 'Isi Data Pengunjung' }}
                </p>
                <!-- <p class="font-size-10 text-black font-weight-400 m-0">{{ item.email ? item.email :
              'Alamat Email belum diisi' }}</p>
                <p class="font-size-10 text-black font-weight-400 m-0">{{ item.phonenumber ? item.phonenumber :
              'Nomor Handphone belum diisi' }}</p> -->
              </div>
              <div class="font-size-12 cursor-pointer align-self-center">
                <img src="../assets/img/icon/fi-rr-angle-small-right.png" class="icon-12"
                  v-if="item?.isDone == false" />
                <img src="../assets/img/icon/check-success.png" class="icon-12" v-else />
              </div>
            </button>
            <div class="text-danger font-size-10 mt-1">
              <p class="m-0" v-if="notDoneVisitor && item?.isDone == false">Data Tiket Pengunjung tidak boleh kosong</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="['footer-form', { 'rounded-footer': showDetail }]">
      <div class="container-summary" v-if="showDetail == true">
        <div class="summary-detail mb-2">
          <div class="summary-title font-weight-600">Rincian Pembayaran</div>
          <b-icon-x-lg class="summary-detail-close cursor-pointer" @click="showDetail = false"></b-icon-x-lg>
        </div>
        <div class="card-summary">
          <div class="container-ticket mb-2">
            <img src="../assets/img/icon/fi-rr-ticket-grey.png" class="icon-12 me-1"> <span
              class="font-weight-600">Tiket Kamu</span>
            <div class="mt-2">
              <div class="total-ticket" v-for="(item, key) in ticketData" :key="key">
                <span v-if="item.qty > 0">{{ item.qty }}x {{ item.category }}</span>
                <span v-if="item.qty > 0">{{ formatCurrency(item.priceQty) }}</span>
              </div>
            </div>
            <div class="toast-fixed info mt-3">
              <div class="message-claimed">
                <img src="../assets/img/fi-rr-info.png">
                <span class="ms-2">Total Pembayaran tiket belum termasuk Biaya Layanan pada masing-masing metode
                  pembayaran yang digunakan</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="toast-fixed danger font-size-10 mt-2 p-2 text-danger" v-if="qtyTotal >= 5">Anda hanya bisa memesan
        maksimal 5 Tiket untuk satu kali pesan
      </div> -->
      <div class="summary-qty mb-3 mt-2">
        <span>Total Pembayaran</span>
        <span :class="['font-weight-600', { 'cursor-pointer': showDetail == false }]" @click="showDetail = true"> {{
              formatCurrency(ticketSummary.priceTotal) }} <img src="../assets/img/icon/fi-rr-angle-small-down.png"
            class="icon-arrow-price" v-if="showDetail == false"> <img src="../assets/img/icon/fi-rr-angle-small-up.png"
            class="icon-arrow-price" v-else> </span>
      </div>
      <button class="btn btn-primary w-100" type="button" @click="checkData(true)">
        Pembayaran
      </button>
    </div>
    <b-modal size="sm" ref="failed-modal" id="failed-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <img src="../assets/img/warning-icon.png" class="warning-icon mb-2">
        <p class="font-size-20 font-weight-600 mb-2">{{ error?.title }}</p>
        <p class="font-size-12 font-weight-400 mb-5">{{ error?.message }}</p>
        <button type="button" class="btn btn-primary btn-sm" @click="$bvModal.hide('failed-modal')">Ok, Terima
          Kasih</button>
      </div>
    </b-modal>
    <b-modal size="sm" ref="confirm-modal" id="confirm-modal" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <p class="font-size-12 font-weight-600 mb-2">Apakah Anda yakin ingin melanjutkan Pembayaran?</p>
        <p class="font-size-10 font-weight-400 mb-4">Pastikan Data Pemesan dan Data Pengunjung sudah sesuai. Ketika
          melanjutkan pembayaran tidak bisa mengubah Data Pemesan dan Pengunjung.</p>
        <div class="w-100 me-0 d-flex flex-row justify-content-end align-items-center">
          <button type="button" class="btn button-default text-primary me-3 font-size-10 font-weight-600"
            @click="$bvModal.hide('confirm-modal')">Cancel</button>
          <button type="button" class="btn btn-primary font-size-10 font-weight-600 btn-xxsm"
            @click="orderTicket()">Pembayaran</button>
        </div>
      </div>
    </b-modal>
    <b-modal size="sm" ref="confirm-modal-back" id="confirm-modal-back" centered hide-footer hide-header no-close-on-backdrop
      no-close-on-esc>
      <div class="text-center">
        <p class="font-size-12 font-weight-600 mb-2">Apakah Anda yakin ingin meninggalkan halaman ini?</p>
        <p class="font-size-10 font-weight-400 mb-4">Anda harus membatalkan pesanan terlebih dahulu untuk meninggalkan
          halaman ini</p>
        <div class="w-100 me-0 d-flex flex-row justify-content-end align-items-center">
          <button type="button" class="btn button-default text-primary me-3 font-size-10 font-weight-600"
            @click="$bvModal.hide('confirm-modal-back')">Tetap Disini</button>
          <button type="button" class="btn button-rounded bg-danger font-size-10 font-weight-600 btn-xxsm"
            @click="BackToEventWithActiveRoom()">Cancel Order</button>
        </div>
      </div>
    </b-modal>
    <Loading :isLoading="loading" />
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
export default {
  components: {
    Loading,
    HeaderComp,
  },
  data() {
    return {
      showDetail: false,
      isDisabledRef: true,
      loading: false,
      formVisitor: [],
      ticketData: [],
      imgBanner: null,
      eventDate: localStorage.getItem('event_date'),
      eventName: localStorage.getItem('event_name'),
      eventSetting: null,
      hoursTimer: 0,
      minutesTimer: 0,
      secondsTimer: 0,
      ticketSummary: {
        qtyTotal: 0,
        priceTotal: 0
      },
      formOrderer: {
        name: "",
        email: "",
        phonenumber: ""
      },
      error: {
        title: "",
        message: ""
      },
      notFilledOrder: false,
      notDoneVisitor: false,
      formBody: {},
      isReadyPayment: false,
      ticket_order: 1,
      bundle_counters: {},
      category_bundles: JSON.parse(localStorage.getItem('ticketData')),
    };
  },

  beforeDestroy() {
    clearInterval(this.intervalData);
  },
  methods: {
    initializeBundleCounters() {
      this.category_bundles.forEach(bundle => {
        this.$set(this.bundle_counters, bundle.category_id, 0);
      });
    },
    createVisitor(data) {
      let counter = 1;
      data.forEach((item) => {      
        for (var a = 0; a < (item.bundlingQty != 0 ? (item.qty * item.bundlingQty) : item.qty); a++) {
          this.formVisitor.push(
            {
              id: counter,
              category: item.category,
              category_id: item.category_id,
              name: "",
              phonenumber: "",
              email: "",
              isDone: false,
              bundling_qty: item.bundlingQty,
              ticket_type: item.ticket_type
            }
          )
          counter += 1;
        }
        console.log("createVisitor counter")
        console.log(counter)
      });
    },
    BackToEventWithActiveRoom() {
      let event_id = localStorage.getItem('event_id');
      let form_id = localStorage.getItem('form_id');
      let base_url_event = localStorage.getItem('base_url_event');

      axios.post("/buyers/orders/exit-room/" + event_id, { form_id: form_id }).then((res) => {
        console.log(res)
        localStorage.removeItem('countDownDate');
        localStorage.removeItem('ticketData');
        window.location.href = base_url_event;
      });
    },
    editVisitor(key) {
      localStorage.setItem('dataVisitor', JSON.stringify(this.formVisitor));
      localStorage.setItem('editDataVisitor', JSON.stringify(this.formVisitor[key]));
      this.$router.push('/form-visitor');
    },
    checkData(isSubmit) {
      let notDone = this.formVisitor.find(o => o.isDone == false);
      let lastPath = localStorage.getItem('lastPathData');
      let event_id = localStorage.getItem('event_id');
      let form_id = localStorage.getItem('form_id');

      if(this.isReadyPayment){
        axios.post("/buyers/orders/exit-room/" + event_id, { form_id: form_id }).then((res) => {
          console.log(res)
        });
      }

      if (isSubmit) {
        if (this.formOrderer.name == "" || this.formOrderer.email == "" || this.formOrderer.phonenumber == "" || notDone) {
          this.notDoneVisitor = notDone;
          if (this.formOrderer.name == "" || this.formOrderer.email == "" || this.formOrderer.phonenumber == "") {
            this.notFilledOrder = true;
          } else {
            this.notFilledOrder = false;
          }
        } else {
          this.notFilledOrder = false;
          this.notDoneVisitor = false;
          if (isSubmit) {
            this.confirmOrder()
          }
        }
      }

      if (lastPath == 'form-data' || lastPath == 'form-visitor') {
        if (this.formOrderer.name == "" || this.formOrderer.email == "" || this.formOrderer.phonenumber == "") {
          this.notFilledOrder = true;
        } else {
          this.notFilledOrder = false;
        }

        if (notDone) {
          this.notDoneVisitor = true;
        } else {
          this.notDoneVisitor = false;
        }
      }
    },
    confirmOrder() {
      this.$bvModal.show('confirm-modal');
    },
    orderTicket() {
      this.loading = true;
      this.formBody = {
        "event_id": localStorage.getItem('event_id'),
        "platform_key": localStorage.getItem('platform_key'),
        "buyer_name": this.formOrderer.name,
        "buyer_email": this.formOrderer.email,
        "buyer_phone": this.formOrderer.phonenumber,
        "purchased_items": [],
        "form_id" : localStorage.getItem('form_id')
      }

      let like = this;

      Promise.all(like.formVisitor.map((item) => like.addAttendee(item))).then(() => {
        // console.log(item)
        console.log(this.formBody)
        axios.post("/buyers/orders", this.formBody).then((res) => {
          localStorage.setItem('lastPathData', 'detail-order');
          const token = res.data.data.order_token;
          localStorage.setItem('token', token);
          localStorage.setItem('token_order', token);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          localStorage.setItem('stepOrder', 'data');
          // const platform_key = localStorage.getItem('platform_key');
          // if (platform_key == 'MGO_IOS' || platform_key == 'MGO_ANDROID') {
          //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          //   const tokenStep = { step: 'order', token: token };
          //   const jsonTokenStep = encodeURIComponent(JSON.stringify(tokenStep));
          //   if (/android/i.test(userAgent)) {
          //     JSBridge.createToken(jsonTokenStep); // eslint-disable-line
          //   } else {
          //     window.webkit.messageHandlers.createToken.postMessage(jsonTokenStep);
          //   }
          // }
          like.loading = false;
          this.isReadyPayment = true;
          like.$router.push('/payment-method');
        }).catch((error) => {
          like.loading = false;
          if (error?.response?.data?.title || error?.response?.data?.message) {
            like.error.title = error?.response?.data?.title;
            like.error.message = error?.response?.data?.message;
            like.$bvModal.show('failed-modal');
          } else {
            like.loading = false;
            like.$router.push('/busy-server');
          }
        });
      });
    },
    addAttendee(item) {
      console.log("item hahaha")
      console.log(item)
      console.log("this.formBody.purchased_items.length")
      console.log(this.formBody.purchased_items.length)
      console.log("counterTicket")
      console.log(this.counterTicket);

      console.log("this.category_bundles")
      console.log(this.category_bundles)
      if (item.ticket_type === "BUNDLE"){
        const bundle = this.category_bundles.find(b => b.category_id === item.category_id);
        if (bundle) {
          this.bundle_counters[item.category_id]++;
        this.formBody.purchased_items.push({
          "category_id": item.category_id,
          "attendee_name": item.name,
          "attendee_email": item.email,
          "attendee_phone": item.phonenumber,
          "ticket_type": item.ticket_type === "" ? "REGULAR" : item.ticket_type,
          "ticket_order": this.ticket_order
        });

        if (this.bundle_counters[item.category_id] === bundle.bundlingQty) {
            this.ticket_order++; 
            this.bundle_counters[item.category_id] = 0; 
          }
        }
        console.log("this.bundle_counters[item.category_id]")
        console.log(this.bundle_counters)

      }  else if (item.ticket_type === "REGULAR") {
          item.ticket_order = this.ticket_order; 
            this.formBody.purchased_items.push({
            "category_id": item.category_id,
            "attendee_name": item.name,
            "attendee_email": item.email,
            "attendee_phone": item.phonenumber,
            "ticket_type": item.ticket_type === "" ? "REGULAR" : item.ticket_type,
            "ticket_order": this.ticket_order
          });
          this.ticket_order++; 
        }
      this.counterTicket++
},
    countdownTimer() {
    let like = this;
    let event_id = localStorage.getItem('event_id');
    let form_id = localStorage.getItem('form_id');
    let countdown = 10 * (1 * 60 * 1000); 
    let now = new Date().getTime();

    let storedCountDownDate = localStorage.getItem('countDownDate');
    var countDownDate  = 0;

    if (storedCountDownDate) {
            countDownDate = parseInt(storedCountDownDate, 10);
    } else {
            countDownDate = now + countdown;
        localStorage.setItem('countDownDate', countDownDate);
    }

    this.intervalData = setInterval(function () {
        var now = new Date().getTime();
        var distance = countDownDate - now;

        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
            clearInterval(like.intervalData);
            let base_url_event = localStorage.getItem('base_url_event');
            localStorage.clear();
            axios.post("/buyers/orders/exit-room/" + event_id, { form_id: form_id }).then((res) => {
                console.log(res);
                window.location.href = base_url_event;
            }).catch((error) => {
                console.log(error);
                window.location.href = base_url_event;
            })
        } else {
            like.hoursTimer = hours;
            like.minutesTimer = minutes;
            like.secondsTimer = seconds;
        }
    }, 1000);
}
  },

  computed: {

  },

  mounted() {
    localStorage.setItem('currentPath', '/detail-order');
    let path = localStorage.getItem('lastPathData');
    let stepOder = localStorage.getItem('stepOrder');
    let confirmBack = localStorage.getItem('confirmBack');
    console.log(confirmBack)
    this.countdownTimer();
    if (path) {
      if (path == 'choose-ticket') {
        localStorage.removeItem('formOrderer');
        localStorage.removeItem('dataVisitor');
      }

      // if (path == 'payment-method') {
      //   if (stepOder && stepOder == 'data') {
      //     if (confirmBack && confirmBack == 'false') {
      //       this.$router.push('/payment-method');
      //     }
      //   }
      // }
    }

    let visitor = localStorage.getItem('dataVisitor');
    if (visitor) {
      this.formVisitor = JSON.parse(visitor);
    }

    let formOrder = localStorage.getItem('formOrderer');
    if (formOrder) {
      this.formOrderer = JSON.parse(formOrder);
    }

    let ticketDatas = localStorage.getItem('ticketData');
    if (ticketDatas) {
      this.ticketData = JSON.parse(ticketDatas);
      if (this.formVisitor.length < 1) {
        this.createVisitor(this.ticketData);
      }
    }

    let ticketSummarys = localStorage.getItem('ticketSummary');
    if (ticketSummarys) {
      this.ticketSummary = JSON.parse(ticketSummarys);
    }

    let setting = localStorage.getItem('event_setting');
    if (setting) {
      this.eventSetting = JSON.parse(setting);
    }

    let banner = localStorage.getItem('banner_new');
    if (banner) {
      let bannerSet = JSON.parse(localStorage.getItem('banner_new'));
      this.imgBanner = bannerSet;
    }

    this.checkData();
    this.initializeBundleCounters();
  },
};
</script>

<style lang="scss" scoped>
.summary-detail-close {
  font-size: 1rem;
  color: #8E8E93;
}

.sub-title-container {
  font-size: 0.625rem;
  font-weight: 400;
  color: #000;
}

.disabled-qty {
  color: #647179 !important;
  cursor: not-allowed;
}

.rounded-footer {
  border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
}

.message-claimed img {
  margin-top: 3px;
  width: 0.75rem;
  height: 0.75rem;
}

.message-claimed span {
  font-size: 0.625rem;
}

.total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
}

.container-ticket i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}

.container-ticket {
  padding: 2vw;
  font-size: 0.75rem;
}

.container-ticket-card {
  padding-top: 9px;
  font-size: 0.75rem;
  color: #000;
}

.container-ticket-card img {
  width: 0.75rem;
}

.container-ticket-card i {
  font-size: 0.75rem;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  color: #AEAEB2;
}

.card-summary {
  padding: 2vw;
  border: 1px solid #E7ECF4;
  border-radius: 8px;
}

.summary-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.summary-detail i {
  font-size: 1rem;
  color: #8E8E93;
}

.summary-qty {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.icon-arrow-price {
  width: 0.875rem;
  height: 0.875rem;
  align-self: center;
}

.link-text {
  text-decoration: underline;
}

.disclaimer-text {
  padding: 2vw;
  font-size: 0.625rem;
}

.text-qty {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.5px;
  text-align: center;
  display: inline-flex;
}

.icon-arrow {
  font-size: 0.75rem;
  line-height: 12px;
  align-self: center;
}

.icon-qty {
  margin-top: 3px;
  font-size: 0.75rem;
  line-height: 13px;
  letter-spacing: -0.5px;
  display: inline-flex;
}

.ticket-qty {
  font-size: 0.75rem;
  align-self: center;
}

.ticket-detail-name {
  color: #636366;
  text-align: left !important;
}

.cat-visit {
  border: 1px solid #F2F5F7;
  border-radius: 8px;
  padding: 3vw;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.sub-sub-title {
  border-bottom: none !important;
  font-size: 0.625rem;
  /* font-weight: bold;
  margin: 0 0 30px; */
  cursor: pointer;
}

.sub-sub-title span {
  vertical-align: middle;
}

.header-title {
  padding: 2vw 2vw;
  background: #F2F5F7;
  border-radius: 4px;
}

.desc-card {
  background: #F2F5F7;
  border-radius: 8px;
  padding: 4vw;
  color: #232323;
}

.desc-title {
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 16px;
}

.address-redirect {
  font-size: 16px;
}

.address-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.address-city {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  color: #8E8E93;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.maps-img img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.title-event {
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.banner-small {
  border: 1px solid #E5E5EA;
  border-radius: 8px;
  width: 19vw;
  height: 7.9vw;
}

.event-calendar {
  font-size: 0.75rem;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.event-calendar span {
  font-weight: 400;
  color: #000;
}

.event-calendar img {
  width: 0.75rem;
}

.event-calendar i {
  color: #AEAEB2;
}

.img-banner-bg {
  background-image: url("../assets/img/banner.png");
  background-repeat: no-repeat;
  background-size: 100vw 51vw;
}

.container-content {
  background-color: white;
  padding: 4vw;
  margin-top: 49vw;
  border-radius: 16px 16px 0px 0px;
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0vw 4.1vw 4.1vw 4.1vw;
}

.footer-form {
  width: 100%;
  padding: 4vw 4vw;
  box-shadow: 4px 4px 20px 0px #00000033;
}
</style>