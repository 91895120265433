var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-page"
  }, [_c('HeaderComp', {
    attrs: {
      "url": "#",
      "isListEvent": "",
      "isBackToHome": ""
    }
  }), _vm._l(_vm.ticketDataUpcoming, function (item, key) {
    return _c('div', {
      key: key
    }, [_c('div', {
      staticClass: "card-page"
    }, [_c('div', {
      staticClass: "card-shadow mb-3"
    }, [_c('div', [_c('img', {
      staticClass: "main-image",
      attrs: {
        "src": _vm.imageBannersUpcoming[key]
      }
    })]), _c('div', {
      staticClass: "title-event content-space-between"
    }, [_c('span', {
      staticClass: "font-weight-600"
    }, [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "event-details"
    }, [_c('div', {
      staticClass: "event-info"
    }, [_c('img', {
      staticClass: "icon-size-desc",
      attrs: {
        "src": require("../assets/img/icon/solar_calendar-bold.png")
      }
    }), _c('span', [_vm._v(_vm._s(_vm.formatDate(item.start_date, item.end_date)))])]), _c('div', {
      staticClass: "event-info"
    }, [_c('img', {
      staticClass: "icon-size-desc",
      attrs: {
        "src": require("../assets/img/icon/heroicons_megaphone-20-solid.png")
      }
    }), _c('span', [_vm._v(_vm._s(item.organizer.name))])]), _c('div', {
      staticClass: "event-info"
    }, [_c('img', {
      staticClass: "icon-size-desc",
      attrs: {
        "src": require("../assets/img/icon/subway_location-1.png")
      }
    }), _c('span', [_vm._v(_vm._s(item.address))])])]), _c('hr', {
      staticClass: "blue-line"
    }), _c('span', [_vm._v("Mulai dari")]), _c('div', {
      staticClass: "container-button"
    }, [_c('div', {
      staticClass: "price-container"
    }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.event_ticket_category[0].price)))])]), _c('div', {
      staticClass: "button-container"
    }, [_c('button', {
      staticClass: "btn-pesan-tiket",
      on: {
        "click": function ($event) {
          return _vm.goToEvent(item.event_url);
        }
      }
    }, [_vm._v("Pesan Tiket")])])])])])]);
  }), _vm._l(_vm.ticketDataPast, function (item, keypast) {
    return _c('div', {
      key: keypast
    }, [_c('div', {
      staticClass: "card-page"
    }, [_c('div', {
      staticClass: "card-shadow mb-3"
    }, [_c('div', [_c('img', {
      staticClass: "main-image",
      attrs: {
        "src": _vm.imageBannersPast[keypast]
      }
    })]), _c('div', {
      staticClass: "title-event content-space-between"
    }, [_c('span', {
      staticClass: "font-weight-600"
    }, [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "event-details"
    }, [_c('div', {
      staticClass: "event-info"
    }, [_c('img', {
      staticClass: "icon-size-desc",
      attrs: {
        "src": require("../assets/img/icon/solar_calendar-bold.png")
      }
    }), _c('span', [_vm._v(_vm._s(_vm.formatDate(item.start_date, item.end_date)))])]), _c('div', {
      staticClass: "event-info"
    }, [_c('img', {
      staticClass: "icon-size-desc",
      attrs: {
        "src": require("../assets/img/icon/heroicons_megaphone-20-solid.png")
      }
    }), _c('span', [_vm._v(_vm._s(item.organizer.name))])]), _c('div', {
      staticClass: "event-info"
    }, [_c('img', {
      staticClass: "icon-size-desc",
      attrs: {
        "src": require("../assets/img/icon/subway_location-1.png")
      }
    }), _c('span', [_vm._v(_vm._s(item.address))])])]), _c('hr', {
      staticClass: "blue-line"
    }), _c('span', [_vm._v("Mulai dari")]), _c('div', {
      staticClass: "container-button"
    }, [_c('div', {
      staticClass: "price-container"
    }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.event_ticket_category[0].price)))])]), _vm._m(0, true)])])])]);
  }), _c('Loading', {
    attrs: {
      "isLoading": _vm.loading
    }
  })], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "button-container"
  }, [_c('button', {
    staticClass: "btn-pesan-tiket-disable"
  }, [_vm._v("Pesan Tiket")])]);
}]

export { render, staticRenderFns }