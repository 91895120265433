<template>
  <div class="container-page">
    <HeaderComp title="" url="/detail-order"/>
    <div class="card-page">
      <div class="form-order">
        <div class="title-page mb-3">Data Pengunjung</div>
        <div class="toast-fixed info mb-3">
          <div class="message-claimed">
            <img src="../assets/img/fi-rr-info.png" class="me-1"/>
            <div class="w-100">
              <p class="m-0 font-size-12 font-weight-600 text-black">Pastikan data yang diisi benar!</p>
              <p class="m-0 font-size-10 font-weight-400 text-black">E-Ticket akan diterbitkan dan dikirimkan melalui
                Alamat Email yang Anda isi pada form dibawah</p>
            </div>
          </div>
        </div>
        <div class="content-space-between">
          <p class="font-size-14 text-black font-weight-400">Tiket Kategori</p>
          <div class="badge-ticket">{{ formVisitor?.category }}</div>
        </div>
        <div class="content-space-between mb-2" v-if="formVisitor?.id == 1">
          <p class="font-size-14 text-black font-weight-400">Sama dengan Data Pemesan</p>
          <div class="form-check form-switch form-switch-md">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" :value="true"
              v-model="sameOrderer" :disabled="!dataOrder">
          </div>
        </div>
        <div class="mb-2">
          <div class="form-floating">
            <input type="text" :class="['form-control', { 'border-danger': $v.formVisitor.name.$error }]" id="name"
              placeholder="Masukkan Nama Lengkap Anda" v-model="$v.formVisitor.name.$model">
            <label for="name">Nama Lengkap <span class="text-danger required-label">*</span></label>
          </div>
          <div class="text-danger font-size-10 mt-1" v-if="$v.formVisitor.name.$error">
            <p class="m-0" v-if="!$v.formVisitor.name.required">Nama lengkap tidak boleh kosong</p>
            <p class="m-0" v-if="!$v.formVisitor.name.minLength || !$v.formVisitor.name.maxLength">Nama
              lengkap terdiri dari 3 - 40 karakter</p>
          </div>
        </div>
        <div class="mb-2">
          <div class="form-floating">
            <input type="text" :class="['form-control', { 'border-danger': $v.formVisitor.phonenumber.$error }]"
              id="phone" placeholder="Masukkan Nomor Handphone Anda" v-model="$v.formVisitor.phonenumber.$model"
              @focus="duplicatePhone = false"
              @input="formVisitor.phonenumber = formVisitor.phonenumber.replace(/[^0-9]/g, '')" @blur="checkPhone">
            <label for="phone">Nomor Handphone <span class="text-danger required-label">*</span></label>
          </div>
          <div class="text-danger font-size-10 mt-1" v-if="$v.formVisitor.phonenumber.$error">
            <p class="m-0" v-if="!$v.formVisitor.phonenumber.required">Nomor handphone tidak boleh kosong</p>
            <p class="m-0" v-if="!$v.formVisitor.phonenumber.minLength || !$v.formVisitor.phonenumber.maxLength">Nomor
              Handphone terdiri dari 10 -15 digit</p>
          </div>
          <div class="text-danger font-size-10 mt-1" v-if="duplicatePhone == true">
            <p class="m-0">Nomor Handphone sudah digunakan</p>
          </div>
        </div>
        <div class="mb-2">
          <div class="form-floating">
            <input type="email" :class="['form-control', { 'border-danger': $v.formVisitor.email.$error }]" id="email"
              placeholder="Masukkan Alamat Email Anda" v-model="$v.formVisitor.email.$model"
              @focus="duplicateEmail = false" @blur="checkEmail">
            <label for="email">Alamat Email <span class="text-danger required-label">*</span></label>
          </div>
          <div class="text-danger font-size-10 mt-1" v-if="$v.formVisitor.email.$error">
            <p class="m-0" v-if="!$v.formVisitor.email.required">Alamat email tidak boleh kosong</p>
            <p class="m-0" v-if="!$v.formVisitor.email.email">Format alamat email tidak valid</p>
          </div>
          <div class="text-danger font-size-10 mt-1" v-if="duplicateEmail == true">
            <p class="m-0">Alamat Email sudah digunakan</p>
          </div>
        </div>
      </div>
    </div>
    <div :class="['footer-form', { 'rounded-footer': showDetail }]">
      <button class="btn btn-primary w-100" type="button" :disabled="$v.formVisitor.$invalid"
        @click="saveVisitor()">
        Simpan Data
      </button>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
// import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  components: {
    Loading,
    HeaderComp,
  },
  data() {
    return {
      showDetail: false,
      isDisabledRef: false,
      isLoading: false,
      dataVisitor: [],
      duplicatePhone: false,
      duplicateEmail: false,
      sameOrderer: false,
      dataOrder: null,
      checkedSame: false,
      eventSetting: null,
      formVisitor: {
        id: "",
        name: "",
        email: "",
        phonenumber: "",
        isDone: false,
      }
    };
  },
  validations: {
    formVisitor: {
      name: { required, minLength: minLength(3), maxLength: maxLength(40) },
      email: { required, email },
      phonenumber: { required, minLength: minLength(10), maxLength: maxLength(15) }
    }
  },
  methods: {
    saveVisitor() {
      this.loading = true;
      this.$v.formVisitor.$touch();
      if (this.$v.formVisitor.$error || this.duplicateEmail == true || this.duplicatePhone == true) {
        return false;
      }

      this.formVisitor.isDone = true;

      let findIndex = this.dataVisitor.findIndex(o => o.id == this.formVisitor.id);

      this.dataVisitor[findIndex] = this.formVisitor;

      localStorage.setItem('dataVisitor', JSON.stringify(this.dataVisitor));
      localStorage.setItem('lastPathData', 'form-visitor');
      this.loading = false;
      this.$router.push('/detail-order');
    },
    checkPhone() {
      let phoneCheck = this.dataVisitor.find(o => o.phonenumber == this.formVisitor.phonenumber);
      let ticketUniquePhone = this.eventSetting?.ticket_unique_phone;
      if (ticketUniquePhone == true) {
        if (phoneCheck && phoneCheck?.id == this.formVisitor.id) {
          this.duplicatePhone = false;
        } else if (phoneCheck && phoneCheck?.id !== this.formVisitor.id) {
          this.duplicatePhone = true;
        } else {
          this.duplicatePhone = false;
        }
      } else {
        this.duplicatePhone = false;
      }
    },
    checkEmail() {
      let emailCheck = this.dataVisitor.find(o => o.email == this.formVisitor.email);
      let ticketUniqueEmail = this.eventSetting?.ticket_unique_email;
      if (ticketUniqueEmail == true) {
        if (emailCheck && emailCheck?.id == this.formVisitor.id) {
          this.duplicateEmail = false;
        } else if (emailCheck && emailCheck?.id !== this.formVisitor.id) {
          this.duplicateEmail = true;
          return false;
        } else {
          this.duplicateEmail = false;
        }
      } else {
        this.duplicateEmail = false;
      }
    }
  },
  computed: {
  },
  mounted() {
    let visitorData = localStorage.getItem('editDataVisitor');
    if (visitorData) {
      this.formVisitor = JSON.parse(visitorData);
    }

    let visitor = localStorage.getItem('dataVisitor');
    if (visitor) {
      this.dataVisitor = JSON.parse(visitor);
    }

    let orderer = localStorage.getItem('formOrderer');
    if (orderer) {
      this.dataOrder = JSON.parse(orderer);
      if (this.formVisitor?.id == 1 && (this.dataOrder?.name == this.formVisitor.name) && (this.dataOrder?.phonenumber == this.formVisitor.phonenumber) && (this.dataOrder?.email == this.formVisitor.email)) {
        this.sameOrderer = true;
      } else {
        this.sameOrderer = false;
      }
    }

    let setting = localStorage.getItem('event_setting');
    if (setting) {
      this.eventSetting = JSON.parse(setting);
    }
  },
  watch: {
    sameOrderer: function (val) {
      if (val == true) {
        this.formVisitor.name = this.dataOrder?.name
        this.formVisitor.phonenumber = this.dataOrder?.phonenumber
        this.formVisitor.email = this.dataOrder?.email
        this.checkEmail();
        this.checkPhone();
      } else {
        if (((this.formVisitor.id == 1) && (this.dataOrder?.name == this.formVisitor.name) && (this.dataOrder?.phonenumber == this.formVisitor.phonenumber) && (this.dataOrder?.email == this.formVisitor.email))) {
          this.formVisitor.name = ''
          this.formVisitor.phonenumber = ''
          this.formVisitor.email = ''
          this.checkEmail();
          this.checkPhone();
        }
      }
    },
    'formVisitor.name': {
      handler: function (after, before) {
        if (this.formVisitor.id == 1 && (this.dataOrder?.name == this.formVisitor.name) && (this.dataOrder?.phonenumber == this.formVisitor.phonenumber) && (this.dataOrder?.email == this.formVisitor.email)) {
          this.sameOrderer = true;
        } else {
          this.sameOrderer = false;
        }
      },
      deep: true
    },
    'formVisitor.phonenumber': {
      handler: function (after, before) {
        if (this.formVisitor.id == 1 && (this.dataOrder?.name == this.formVisitor.name) && (this.dataOrder?.phonenumber == this.formVisitor.phonenumber) && (this.dataOrder?.email == this.formVisitor.email)) {
          this.sameOrderer = true;
        } else {
          this.sameOrderer = false;
        }
      },
      deep: true
    },
    'formVisitor.email': {
      handler: function (after, before) {
        if (this.formVisitor.id == 1 && (this.dataOrder?.name == this.formVisitor.name) && (this.dataOrder?.phonenumber == this.formVisitor.phonenumber) && (this.dataOrder?.email == this.formVisitor.email)) {
          this.sameOrderer = true;
        } else {
          this.sameOrderer = false;
        }
      },
      deep: true
    },

  },
};
</script>

<style lang="scss" scoped>
.sub-title-container {
  font-size: 0.625rem;
  font-weight: 400;
  color: #000;
}

.disabled-qty {
  color: #647179 !important;
  cursor: not-allowed;
}

.rounded-footer {
  border-radius: 16px 16px 0px 0px !important;
}

.message-claimed {
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
}

.message-claimed img {
  margin-top: 3px;
  width: 0.75rem;
  height: 0.75rem;
}

.message-claimed span {
  font-size: 0.625rem;
}

.total-ticket {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
}

.container-ticket i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
}

.container-ticket {
  padding: 2vw;
  font-size: 0.75rem;
}

.container-ticket-card {
  padding-top: 9px;
  font-size: 0.75rem;
  color: #000;
}

.container-ticket-card i {
  font-size: 0.75rem;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  color: #AEAEB2;
}

.card-summary {
  padding: 2vw;
  border: 1px solid #E7ECF4;
  border-radius: 8px;
}

.summary-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.summary-detail i {
  font-size: 1rem;
  color: #8E8E93;
}

.summary-qty {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.link-text {
  text-decoration: underline;
}

.disclaimer-text {
  padding: 2vw;
  font-size: 0.625rem;
}

.text-qty {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.5px;
  text-align: center;
  display: inline-flex;
}

.icon-arrow {
  font-size: 0.75rem;
  line-height: 12px;
  align-self: center;
}

.icon-qty {
  margin-top: 3px;
  font-size: 0.75rem;
  line-height: 13px;
  letter-spacing: -0.5px;
  display: inline-flex;
}

.ticket-qty {
  font-size: 0.75rem;
  align-self: center;
}

.ticket-detail-name {
  color: #636366;
}

.cat-visit {
  border: 1px solid #F2F5F7;
  border-radius: 8px;
  padding: 3vw;
  display: flex;
  justify-content: space-between;
}

.sub-sub-title {
  border-bottom: none !important;
  font-size: 0.625rem;
  /* font-weight: bold;
  margin: 0 0 30px; */
  cursor: pointer;
}

.sub-sub-title span {
  vertical-align: middle;
}

.header-title {
  padding: 2vw 2vw;
  background: #F2F5F7;
  border-radius: 4px;
}

.desc-card {
  background: #F2F5F7;
  border-radius: 8px;
  padding: 4vw;
  color: #232323;
}

.desc-title {
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 16px;
}

.address-redirect {
  font-size: 16px;
}

.address-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.address-city {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75vw;
  color: #8E8E93;
  line-height: 14.52px;
  font-size: 0.75rem;
  font-weight: 400;
  white-space: nowrap;
}

.maps-img img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.title-event {
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.banner-small {
  border: 1px solid #E5E5EA;
  border-radius: 8px;
  width: 19vw;
  height: 7.9vw;
}

.event-calendar {
  font-size: 0.75rem;
  padding-bottom: 9px;
  border-bottom: 1px solid #F2F2F2;
}

.event-calendar span {
  font-weight: 400;
  color: #000;
}

.event-calendar i {
  color: #AEAEB2;
}

.img-banner-bg {
  background-image: url("../assets/img/banner.png");
  background-repeat: no-repeat;
  background-size: 100vw 51vw;
}

.container-content {
  background-color: white;
  padding: 4vw;
  margin-top: 49vw;
  border-radius: 16px 16px 0px 0px;
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0vw 4.1vw 4.1vw 4.1vw;
}

.footer-form {
  width: 100%;
  padding: 4vw 4vw;
  box-shadow: 4px 4px 20px 0px #00000033;
}
</style>