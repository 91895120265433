<template>
  <div class="container-page">
    <HeaderComp url="#" isListEvent isBackToHome />
    <div v-for="(item, key) in ticketDataUpcoming" :key="key">
      <div class="card-page">
        <div class="card-shadow mb-3">
          <div>
            <img :src="imageBannersUpcoming[key]" class="main-image" />
          </div>
          <div class="title-event content-space-between">
            <span class="font-weight-600">{{ item.name }}</span>
          </div>
          <div class="event-details">
            <div class="event-info">
              <img src="../assets/img/icon/solar_calendar-bold.png" class="icon-size-desc" />
              <span>{{ formatDate(item.start_date, item.end_date) }}</span>
            </div>
            <div class="event-info">
              <img src="../assets/img/icon/heroicons_megaphone-20-solid.png" class="icon-size-desc" />
              <span>{{ item.organizer.name }}</span>
            </div>
            <div class="event-info">
              <img src="../assets/img/icon/subway_location-1.png" class="icon-size-desc" />
              <span>{{ item.address }}</span>
            </div>
          </div>
          <hr class="blue-line" />
          <span>Mulai dari</span>
          <div class="container-button">
            <div class="price-container">
              <span>{{ formatCurrency(item.event_ticket_category[0].price) }}</span>
            </div>
            <div class="button-container">
              <button @click=goToEvent(item.event_url) class="btn-pesan-tiket">Pesan Tiket</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, keypast) in ticketDataPast" :key="keypast">
      <div class="card-page">
        <div class="card-shadow mb-3">
          <div>
            <img :src="imageBannersPast[keypast]" class="main-image" />
          </div>
          <div class="title-event content-space-between">
            <span class="font-weight-600">{{ item.name }}</span>
          </div>
          <div class="event-details">
            <div class="event-info">
              <img src="../assets/img/icon/solar_calendar-bold.png" class="icon-size-desc" />
              <span>{{ formatDate(item.start_date, item.end_date) }}</span>
            </div>
            <div class="event-info">
              <img src="../assets/img/icon/heroicons_megaphone-20-solid.png" class="icon-size-desc" />
              <span>{{ item.organizer.name }}</span>
            </div>
            <div class="event-info">
              <img src="../assets/img/icon/subway_location-1.png" class="icon-size-desc" />
              <span>{{ item.address }}</span>
            </div>
          </div>
          <hr class="blue-line" />
          <span>Mulai dari</span>
          <div class="container-button">
            <div class="price-container">
              <span>{{ formatCurrency(item.event_ticket_category[0].price) }}</span>
            </div>
            <div class="button-container">
              <button class="btn-pesan-tiket-disable">Pesan Tiket</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :isLoading="loading" />
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
import axios from "axios";
import HeaderComp from "../components/HeaderComp.vue";
import { marked } from 'marked';

export default {
  components: {
    Loading,
    HeaderComp,
    marked
  },
  data() {
    return {
      imageBannersUpcoming: [],
      imageBannersPast: [],
      loading: false,
      ticketDataPast: [],
      ticketDataUpcoming: [],
      error: {
        title: "",
        message: ""
      }
    };
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    },
    formatDate(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
        if (
      start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      start.getDate() === end.getDate()
    ) {
      return `${start.getDate()} ${start.toLocaleString("default", { month: "long" })} ${start.getFullYear()}`;
    }

    if (start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth()) {
      return `${start.getDate()} - ${end.getDate()} ${end.toLocaleString("default", { month: "long" })} ${end.getFullYear()}`;
    }
    
    if (start.getFullYear() === end.getFullYear()) {
      return `${start.getDate()} ${start.toLocaleString("default", { month: "long" })} - ${end.getDate()} ${end.toLocaleString("default", { month: "long" })} ${end.getFullYear()}`;
    }
      return `${start.getDate()} ${start.toLocaleString("default", { month: "long" })} ${start.getFullYear()} - ${end.getDate()} ${end.toLocaleString("default", { month: "long" })} ${end.getFullYear()}`;
  },
  getListEvent(platform_key) {
  this.loading = true;
  axios.get(`/buyers/events?platform_key=` + platform_key)
    .then((res) => {
      this.loading = false;
      if (res.data.data.upcoming && res.data.data.upcoming.length > 0) {
        this.ticketDataUpcoming = res.data.data.upcoming.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        this.imageBannersUpcoming = new Array(this.ticketDataUpcoming.length).fill(null);

        this.ticketDataUpcoming.forEach((item, index) => {
          this.getUrlBlob(item.banner, index, 'upcoming');
        });
      } else {
        this.ticketDataUpcoming = []; 
        this.imageBannersUpcoming = [];
      }

      if (res.data.data.past && res.data.data.past.length > 0) {
        this.ticketDataPast = res.data.data.past.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        this.imageBannersPast = new Array(this.ticketDataPast.length).fill(null);
        this.ticketDataPast.forEach((item, index) => {
          this.getUrlBlob(item.banner, index, 'past');
        });
      } else {
        this.ticketDataPast = []; 
        this.imageBannersPast = [];
      }
    })
    .catch((error) => {
      this.loading = false;
      console.error(error);
    });
},

    getUrlBlob(val, index, type) {
      if (val) {
        const sig_url = `${axios.defaults.baseURL}/buyers${val}`;
        fetch(sig_url)
          .then(response => response.blob())
          .then(blob => {
            const fr = new FileReader();
            fr.onload = () => {
              if (type === 'upcoming') {
                this.$set(this.imageBannersUpcoming, index, fr.result);
              } else if (type === 'past') {
                this.$set(this.imageBannersPast, index, fr.result);
              }
            };
            fr.readAsDataURL(blob);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    goToEvent(url) {
      this.$router.push('/event/' + url);
    }
  },
  mounted() {
  if (this.$route.query.platform_key) {
    localStorage.setItem('platform_key', this.$route.query.platform_key);
    this.getListEvent(this.$route.query.platform_key);
    localStorage.setItem('currentPath', '/event');
    localStorage.setItem('base_url_list_event', window.location.href);
    localStorage.setItem('url_event', this.$route.params.name);
  } else {
    localStorage.setItem('platform_key', this.$route.query.platform_key);
    this.getListEvent(this.$route.query.platform_key);
    localStorage.setItem('currentPath', '/event');
    localStorage.setItem('base_url_list_event', window.location.href);
    localStorage.setItem('url_event', this.$route.params.name);
  }

  }
};
</script>

<style lang="scss" scoped>
.container-page {
  overflow-y: auto;
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 350px;
  margin: auto;
}

.card-shadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.card-page img.main-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px;
}

.card-page .card {
  max-width: 350px;
  /* Atur lebar maksimal kartu agar konsisten */
  margin: auto;
  /* Buat kartu berada di tengah */
}


.event-details {
  margin-top: 15px;
}

.event-info {
  display: flex;
  align-items: center;
}

.event-info img {
  width: 18px;
  margin-right: 10px;
  vertical-align: middle;
}

.title-event {
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 600;
}

.blue-line {
  height: 3px;
  color:  #1C6DB8;
  margin: 10px 0;
  opacity: 100%;
}

.container-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-container {
  font-weight: bold;
  color:#1C6DB8;
}

.button-container {
  display: flex;
  justify-content: center;
}

.btn-pesan-tiket {
  padding: 10px 20px;
  background-color: #1C6DB8;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-pesan-tiket-disable {
  padding: 10px 20px;
  background-color: #929393;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-pesan-tiket:hover {
  background-color: #1C6DB8;
}

.event-info {
  display: flex;
  align-items: flex-start;
}

.icon-wrapper {
  margin-right: 8px;
}

.icon-size-desc {
  width: 20px;
  height: 20px;
}

.text-wrapper {
  display: inline-block;
  word-break: break-word;
}
</style>